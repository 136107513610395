<template>
  <div>
    <el-card class='box-card'>
      <el-tabs v-model='activeName' @tab-click='tabHandleClick'>
        <el-tab-pane label='APP设置' name='first'>
          <el-divider content-position='left'>首页轮播图</el-divider>
          <el-row>
            <el-col :span='12' class='index-menu-item' v-for='(item,index) in settings.bannerImages' :key='index'
                    style='border-bottom: 1px solid #f4f4f4;border-right: 1px solid #f4f4f4;'
                    :style="{padding: index %2 == 1?'0 0 0 10px': ''}">
              <div class='left'>
                <el-upload
                  class='avatar-uploader'
                  :action='getAppSettingUploadUrl()'
                  :headers='uploadHeaders'
                  :show-file-list='false'
                  :on-success='handleBannerImagesSuccess'
                  @click='selectBannerImagesIndex = index'
                >
                  <img v-if='item.img' :src='item.img' class='icon' />
                  <el-icon v-else class='avatar-uploader-icon'>
                    <plus />
                  </el-icon>
                </el-upload>
              </div>
              <div class='right'
                   :style="{margin: index %2 == 1?'0 0 0 10px' : '',padding: index %2 == 1?'0 10px 0 0' : ''}">
                <el-input v-model='item.title' placeholder='请输入'>
                  <template #prepend>图片标题</template>
                </el-input>
                <el-input v-model='item.url' placeholder='请输入' style='margin-top: 10px'>
                  <template #prepend>跳转地址</template>
                </el-input>
              </div>
            </el-col>
          </el-row>
          <el-divider content-position='left'>首页菜单格</el-divider>
          <el-row>
            <el-col :span='12' class='index-menu-item' v-for='(item,index) in settings.appHomeMenus' :key='index'
                    style='border-bottom: 1px solid #f4f4f4;border-right: 1px solid #f4f4f4;'
                    :style="{padding: index %2 == 1?'0 0 0 10px': ''}">
              <div class='left'>
                <el-upload
                  class='avatar-uploader'
                  :action='getAppSettingUploadUrl()'
                  :headers='uploadHeaders'
                  :show-file-list='false'
                  :on-success='handleIndexMenuImgSuccess'
                  @click='selectIndexMenuImgIndex = index'
                >
                  <img v-if='item.img' :src='item.img' class='icon' />
                  <el-icon v-else class='avatar-uploader-icon'>
                    <plus />
                  </el-icon>
                </el-upload>
              </div>
              <div class='right'
                   :style="{margin: index %2 == 1?'0 0 0 10px' : '',padding: index %2 == 1?'0 10px 0 0' : ''}">
                <el-input v-model='item.title' placeholder='请输入'>
                  <template #prepend>菜单标题</template>
                </el-input>
                <el-input v-model='item.url' placeholder='请输入' style='margin-top: 10px'>
                  <template #prepend>菜单地址</template>
                </el-input>
              </div>
            </el-col>
          </el-row>
          <el-divider content-position='left'>首页公告栏</el-divider>
          <div>
            <el-input v-model='settings.appNotice' placeholder='请输入App显示的公告内容'>
              <template #prepend>公告内容</template>
            </el-input>
          </div>
          <el-divider content-position='left'>联系电话</el-divider>
          <div>
            <el-input v-model='settings.telephone' placeholder='请输入客服联系电话'>
              <template #prepend>客服电话</template>
            </el-input>
          </div>
          <el-divider content-position='left'>后台公告</el-divider>
          <div>
            <el-input
              v-model='settings.systemNotice'
              :rows='3'
              type='textarea'
              placeholder='请输入后台公告信息'
            />
          </div>
          <div style='margin-top: 20px;text-align: center;'>
            <el-button type='primary' @click='saveAppSettingInfo'>保存设置</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { Plus } from '@element-plus/icons'
import { getAppSettingUploadUrl, saveAppSetting, getAppSetting } from '@/api/app-setting'
import { ElMessage } from 'element-plus'
import store from '@/store'

const settings = ref({
  // app轮播图列表
  bannerImages: [{
    title: '',
    url: '',
    img: ''
  }, {
    title: '',
    url: '',
    img: ''
  }, {
    title: '',
    url: '',
    img: ''
  }, {
    title: '',
    url: '',
    img: ''
  }],
  // app首页公告
  appNotice: '',
  // app首页菜单格
  appHomeMenus: [{
    title: '',
    url: '',
    img: ''
  }, {
    title: '',
    url: '',
    img: ''
  }, {
    title: '',
    url: '',
    img: ''
  }, {
    title: '',
    url: '',
    img: ''
  }],
  // app联系电话
  telephone: '',
  // 后台系统公告
  systemNotice: ''
})

// 上传携带验证参数
const uploadHeaders = ref({ token: store.getters.token })

// 设置信息
const getAppSettingInfo = async () => {
  const settingResult = await getAppSetting('bannerImages,appNotice,appHomeMenus,telephone,systemNotice')
  if (settingResult.bannerImages) {
    settings.value.bannerImages = JSON.parse(settingResult.bannerImages)
  }
  if (settingResult.appHomeMenus) {
    settings.value.appHomeMenus = JSON.parse(settingResult.appHomeMenus)
  }
  if (settingResult.appNotice) {
    settings.value.appNotice = settingResult.appNotice
  }
  if (settingResult.telephone) {
    settings.value.telephone = settingResult.telephone
  }
  if (settingResult.systemNotice) {
    settings.value.systemNotice = settingResult.systemNotice
  }
}
getAppSettingInfo()

// 保存设置
const saveAppSettingInfo = async () => {
  for (const item of settings.value.bannerImages) {
    if (item.url === '') {
      item.img = ''
      item.title = ''
    }
  }
  await saveAppSetting({
    key: 'bannerImages',
    value: JSON.stringify(settings.value.bannerImages)
  })
  await saveAppSetting({
    key: 'appHomeMenus',
    value: JSON.stringify(settings.value.appHomeMenus)
  })
  await saveAppSetting({
    key: 'appNotice',
    value: settings.value.appNotice
  })
  await saveAppSetting({
    key: 'telephone',
    value: settings.value.telephone
  })
  await saveAppSetting({
    key: 'systemNotice',
    value: settings.value.systemNotice ? settings.value.systemNotice : ''
  })
  ElMessage.success('保存成功')
}

const activeName = reactive('first')

const tabHandleClick = (tab, event) => {
  console.log(tab, event)
}

const selectBannerImagesIndex = ref(0)
// APP轮播图上传成功钩子
const handleBannerImagesSuccess = (res, file) => {
  settings.value.bannerImages[selectBannerImagesIndex.value].img = res.data
}
const selectIndexMenuImgIndex = ref(0)
// APP首页菜单图标上传成功钩子
const handleIndexMenuImgSuccess = (res, file) => {
  settings.value.appHomeMenus[selectIndexMenuImgIndex.value].img = res.data
}
</script>

<style lang='scss'>
.index-menu-item {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .left {
    .icon {
      width: 90px;
      height: 90px;
      display: block;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 90px;
      height: 90px;
      text-align: center;
    }

    .avatar-uploader-icon svg {
      margin-top: 32px; /* (178px - 28px) / 2 - 1px */
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
  }

  .right {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
  }
}
</style>
