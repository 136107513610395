import request from '@/utils/request'

/**
 * 上传应用文件
 * @returns {string}
 */
export const getAppSettingUploadUrl = () => {
  return process.env.VUE_APP_BASE_API + '/appSetting/upload'
}

/**
 * 保存应用设置
 * @param data
 * @returns {*}
 */
export const saveAppSetting = data => {
  return request({
    url: '/appSetting/saveSetting',
    method: 'POST',
    data
  })
}

/**
 * 获取应用设置
 * @param data
 * @returns {*}
 */
export const getAppSetting = key => {
  return request({
    url: `/appSetting/getSetting/${key}`,
    method: 'GET'
  })
}
